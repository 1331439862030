<!-- @format -->

<template>
  <div>
    <MobileHeader />
    <MobileTop />

    <div class="trailer">
      <a
        href="https://www.youtube-nocookie.com/embed/KV1EUpLteik?autoplay=1&rel=0"
        target="_blank"
      >
        <img class="bg" src="@/assets/images/mob_trailer_bg.jpg" alt />
      </a>
      <div class="tip">
        <div class="tip-text">
          Quelle: Youtube
          <br />Zum Ansehen des Videos müssen Sie auf den Play Button klicken.
          Wir möchten Sie darauf hinweisen, dass nach der Aktivierung Daten an
          Youtube übermittelt werden.
        </div>
      </div>
      <div class="mob_packshot">
        <a
          class=""
          href="https://www.amazon.de/Cody-Wie-Hund-Welt-ver%C3%A4ndert/dp/B08DSS7WCX/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Cody&qid=1602073151&sr=8-1&tag= filmweltverle-21"
          target="_blank"
          ><img src="@/assets/images/packshot_mob.png" alt
        /></a>
      </div>
      <img class="bg2" src="@/assets/images/mob_trailer_bg2.jpg" alt />
    </div>

    <!-- Inhalt -->
    <div id="mob-inhalt">
      <MobileExpandableCard
        class="mob-inhalt"
        image="card_inh.jpg"
        imageHeight="400"
        btnTopAlign="left"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="transparent"
        cardBg="#b89b71"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>Inhalt</template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.inhalt.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div class="top-text" v-html="$texte.inhalt.text"></div>
        <div v-html="$texte.inhalt.text2"></div>
      </MobileExpandableCard>
    </div>

    <!-- Fotos -->
    <div id="mob-fotos" @click="foto = 0">
      <MobileCard
        class="mob-fotos"
        image="card_foto.jpg"
        btnTopAlign="left"
        imageHeight="400"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="transparent"
        cardBg="#b89b71"
        cardBorderRadius="0px"
        openButton="Zur Bildergalerie"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>Fotos</template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Martin folgt seinem Hund in dessen Vergangenheit. Impressionen einer
          Reise rund um die Welt, auf der Suche nach Glück!
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileCard>
    </div>

    <!-- Künstlerinnen -->
    <div id="mob-protagonisten">
      <MobileExpandableCard
        class="mob-protagonisten"
        image="card_prota.jpg"
        btnTopAlign="left"
        imageHeight="400"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="transparent"
        cardBg="#b89b71"
        cardBorderRadius="0px"
        wrapperPadding="0px"
        imageAlign="center"
        :headline="false"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>Die Protagonisten</template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Auf seiner Reise begegnet Martin beeindruckenden Menschen und teilt
          seine Gedanken mit ihnen.
        </template>
        <!-- Hidden Maincontent -->
        <artists-mobile />
      </MobileExpandableCard>
    </div>

    <div class="mob-partner">
      <partner />
    </div>

    <BaseFooter :kinostart="kinostart" :billing="true" :youtubenote="false" />
    <BaseModalGallery :foto="foto" />
  </div>
</template>

<script>
  import moment from 'moment'
  import { EventBus } from '@/plugins/event-bus.js'
  import texte from '@/views/texte/all.json'
  import ArtistsMobile from '@/views/ArtistsMobile.vue'
  import Partner from '@/views/Partner.vue'

  export default {
    components: {
      Partner,
      ArtistsMobile,
    },
    data() {
      return {
        foto: null,
        images: [],
        texte: texte,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
    },
    methods: {
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
      emitGewinnspielClick() {
        window.scrollTo(0, 0)
        EventBus.$emit('gewinnspielmodal')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  body {
    background-color: black;
    overflow-x: hidden;
  }
  .trailer {
    position: relative;
  }
  .tip {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    background-color: #bad3d3;
    margin: 0;
    .tip-text {
      padding: 0 1rem;
    }
  }
  .mob_packshot {
    pointer-events: auto;
    position: absolute;
    right: 5%;
    bottom: 25%;
    display: inline-block;
    width: 35%;
    height: 20%;
    img {
      width: 100%;
    }
    .mob_gtop {
      position: absolute;
      width: 100%;
      height: 60%;
    }
    .mob_gbottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }
  }
  .bg2 {
    border-bottom: 1rem solid #fff;
  }
  .mob-protagonisten {
    padding-bottom: 30px;
  }
  .top-text {
    margin-bottom: 16px;
  }
</style>
