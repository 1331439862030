<!-- @format -->

<template>
  <div>
    <div class="protagonisten">
      <div class="wrapper">
        <div style="padding: 0 5% 35px 5%;">
          <p>
            <span style="color: black;">Maike Maja Nowak</span> wurde 1961 in
            Ostdeutschland geboren, lebte viele Jahre in einem einsamen Dorf in
            der Russischen Weite mit einem Rudel wilder Hunde. Sie hat mehrere
            erfolgreiche Bücher über ihre Arbeit veröffentlicht. Mit Titeln wie
            „Wie viel Mensch braucht ein Hund“ oder „Abenteuer Vertrauen.
            Vollkommen, aber nicht perfekt. Was Menschen von Hunden lernen
            können.“ landete sie mehrfach in der Spiegel Bestseller-Liste. Sie
            ist eine international bekannte Wegbereiterin für Mensch wie Hund.
          </p>
        </div>
        <img class src="@/assets/images/mob_prot_02.jpg" alt />
        <div class="artist-text">
          <p>
            <span style="color: black;">Cristina Paun</span> hat in Targoviste,
            Rumänien ca. 6-8‘000 Hundeleben gerettet, seit das Tötungsgesetz
            2014 in Kraft trat. Sie war diejenige, die Cody und Blanche von der
            Straße holte. In der Zwischenzeit führt sie drei Hundeheime in
            Targoviste mit über 1‘500 Hunden, die auf eine Adoption warten. Die
            Dachorganisation „Wonderland“ ist eine Institution in Rumänien, die
            nicht nur Hunden sondern allen Tieren in Not hilft. Nebenbei
            arbeitet Cristina in der Telekommunikationsbranche und steckt einen
            großen Teil ihres Gehalts in die Finanzierung der Hundeheime.
          </p>
        </div>
        <img src="@/assets/images/mob_prot_03.jpg" alt />
        <div class="artist-text">
          <p>
            <span style="color: black;">Mark Rowlands,</span> geboren 1962 in
            Newport, Wales, ist ein britischer Schriftsteller und Professor für
            Philosophie, der an der Universität Miami lehrt. Ein zentrales
            Element von Rowlands Philosophie ist seine Verteidigung einer
            Tierrechtsphilosophie und einer Speziesismuskritischen Position. Mit
            „Animal Rights. A Philosophical Defence“ bzw. „Animal Rights. Moral
            Theory and Practice“ oder „The Philosopher and the Wolf. Lessons
            from the wild on Love, Death and Happiness“ sind ihm viel beachtete
            Werke der Tierrechtsphilosophie gelungen.
          </p>
        </div>
        <img src="@/assets/images/mob_prot_04.jpg" alt />
        <div class="artist-text">
          <p>
            <span style="color: black;">Martin &amp; Selina Skalsky</span> haben
            Cody 2014 adoptiert. Das verheiratete Paar hatte mit Cody viele
            Herausforderungen zu meistern und erlebte im Umgang mit ihm viele
            Veränderungen – auch mit Auswirkungen auf ihr eigenes Leben. In der
            Zwischenzeit ist mit der kleinen Mila ihre erste Tochter ins Rudel
            gekommen.
          </p>
        </div>
        <img src="@/assets/images/mob_prot_05.jpg" alt />
        <div class="artist-text">
          <p>
            <span style="color: black;">Marsha Hickmott</span> gibt Hunden aus
            Rumänien in ihrem Haus in London ein temporäres Zuhause, bevor sie
            weitervermittelt werden. Sie lebt derzeit mit drei Hunden, eine
            davon ist Blanche, die langjährige Weggefährtin von Cody auf den
            Strassen von Targoviste. Darüber hinaus engagiert sich Marsha als
            Fundraiserin für mehrere Tierschutzorganisationen mit speziellem
            Fokus auf rumänische Straßenhunde.
          </p>
        </div>
        <img src="@/assets/images/mob_prot_06.jpg" alt />
        <div class="artist-text">
          <p>
            <span style="color: black;">Lya Battle</span> gründete das „Land der
            Streuner“ – Territorio de Zaguates. In diesem außerordentlichen
            Hunde-Shelter in den Bergen oberhalb Heredia in Costa Rica bietet
            sich über 1300 Hunden eine Heimat. Sie können dort auf einem
            riesigen Grundstück – über 530 Fußballfelder groß – frei leben.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Arrow from 'vue-material-design-icons/ArrowLeftBoldCircle'
  import moment from 'moment'

  export default {
    data() {
      return {
        num: 0,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      active() {
        return `fontWeight: 900 !important;
                    color: black;`
      },
    },
  }
</script>

<style lang="scss">
  /** @format */
  .nova-card__btn-bottom-close {
    padding-right: 5% !important;
  }
  .protagonisten {
    .wrapper {
      padding: 0;
      background-color: $primary;
      .artist-text {
        padding: 35px 5%;
      }
    }
    p {
      font-weight: 400 !important;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      color: #fff;
      span {
        font-weight: 600 !important;
      }
    }
  }
</style>
